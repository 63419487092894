<template>
  <div class="container-fluid">
    <div class="row">
      <div class="title">User Detail</div>
      <div class="col-md-12 col-lg-12">
        <div class="back-link mt-3 mb-4" @click="OnClickedBackBtn">
          <i class="las la-arrow-alt-circle-left"></i>
          <span> Back</span>
        </div>
        <BootStrapAlert/>
      </div>

      <div class="col-md-9 col-lg-9">
        <div class="profile-row">
          <div class="row" v-show="EditTypeEnum.NAME !== this.edit_enabled">
            <label>{{$t("username")}}</label>
            <div class="col-10">
              <span class="text">{{this.selectedUser.name}}</span>
            </div>
            <div class="col-2 text-end">
              <i class="las la-edit" @click="this.OnClickedEditBtn(EditTypeEnum.NAME)"></i>
            </div>
          </div>
          <div class="row" v-show="EditTypeEnum.NAME === this.edit_enabled">
            <label>{{$t("change_username")}}</label>
            <div class="col-10 col-md-6 col-lg-6">
              <input @input="updateNameText"
                     class="form-control input"
                     type="text"
                     :placeholder="[[$t('type_in')]]"
                     :value="this.user.name">
              <div v-for="(item, index) in v$.user.name.$errors" :key="index" class="red-color">
                <span v-if="item.$message">{{ item.$message }}</span>
              </div>
            </div>
            <div class="col-2  col-md-6 col-lg-6 text-end">
              <i class="las la-edit text-gray" @click="this.OnClickedEditBtn(EditTypeEnum.DEFAULT)"></i>
            </div>
            <div class="col-md-12">
              <button class="submit mt-3" @click="this.OnClickedChangeBtn(EditTypeEnum.NAME)">{{$t("change")}}</button>
            </div>
          </div>
          <hr>
        </div>
        <div class="profile-row">
          <div class="row">
            <label>{{$t("email")}}</label>
            <div class="col-10">
              <span class="text">{{this.selectedUser.email}}</span>
            </div>
          </div>
          <hr>
        </div>
        <div class="profile-row">
          <div class="row" v-show="EditTypeEnum.PASSWORD !== this.edit_enabled">
            <label>{{$t("password")}}</label>
            <div class="col-10">
              <span class="text">••••••••••</span>
            </div>
            <div class="col-2 text-end">
              <i class="las la-edit" @click="this.OnClickedEditBtn(EditTypeEnum.PASSWORD)"></i>
            </div>
          </div>

          <div class="row" v-show="EditTypeEnum.PASSWORD === this.edit_enabled">
            <div class="col-10 col-md-4 col-lg-4">
              <label>{{$t("new_password")}}</label>
              <input @input="OnUpdatePasswordText" class="form-control input" type="password" :placeholder="[[$t('type_in')]]">
              <div v-for="(item, index) in v$.user.new_password.$errors" :key="index" class="red-color">
                <span v-if="item.$message">{{ item.$message }}</span>
              </div>
            </div>
            <div class="col-10 col-md-4 col-lg-4">
              <label>{{$t("confirm_password")}}</label>
              <input @input="OnUpdateConfirmPasswordText" class="form-control input" type="password" :placeholder="[[$t('type_in')]]">
              <div v-for="(item, index) in v$.user.confirm_password.$errors" :key="index" class="red-color">
                <span v-if="item.$message">{{ item.$message }}</span>
              </div>
            </div>

            <div class="col-2  col-md-4 col-lg-4 text-end">
              <i class="las la-edit text-gray" @click="this.OnClickedEditBtn(EditTypeEnum.DEFAULT)"></i>
            </div>

            <div class="col-md-12">
              <button class="submit mt-3" @click="this.OnClickedChangeBtn(EditTypeEnum.PASSWORD)">{{$t("change")}}</button>
            </div>
          </div>
          <hr>
        </div>
        <div class="profile-row">
          <div class="row" v-show="EditTypeEnum.ROLE !== this.edit_enabled">
            <label>{{$t("role")}}</label>
            <div class="col-10">
              <span class="text text-uppercase">{{this.selectedUser.role}}</span>
            </div>
            <div class="col-2 text-end">
              <i class="las la-edit" @click="this.OnClickedEditBtn(EditTypeEnum.ROLE)"></i>
            </div>
          </div>

          <div class="row" v-show="EditTypeEnum.ROLE === this.edit_enabled">
            <label>{{$t("change_role")}}</label>
            <div class="col-10 col-md-4 col-lg-4">
              <select class="form-control" @change="this.OnUpdateRole">
                <option v-for="(item, i) in roles" :selected="this.selectedUser.role === item.val" :key="i" :value="item.val">{{item.key}}</option>
              </select>

              <div v-for="(item, index) in v$.user.name.$errors" :key="index" class="red-color">
                <span v-if="item.$message">{{ item.$message }}</span>
              </div>
            </div>
            <div class="col-2  col-md-8 col-lg-8 text-end">
              <i class="las la-edit text-gray" @click="this.OnClickedEditBtn(EditTypeEnum.DEFAULT)"></i>
            </div>
            <div class="col-md-12">
              <button class="submit mt-3" @click="this.OnClickedChangeBtn(EditTypeEnum.ROLE)">{{$t("change")}}</button>
            </div>
          </div>
          <hr>
        </div>

        <div class="profile-row">
          <div class="row">
            <div class="col-10 col-md-4 col-lg-4">
              <label>{{$t("card_brand")}}</label>
              <div class="text">{{this.selectedUser.card_brand}}</div>
            </div>

            <div class="col-10 col-md-4 col-lg-4">
              <label>{{$t("card_last4")}}</label>
              <div class="text" v-show="this.selectedUser.card_last4">●●●● ●●●● ●●●● {{this.selectedUser.card_last4}}</div>
            </div>

            <div class="col-10 col-md-4 col-lg-4">
              <label>{{$t("card_exp_date")}}</label>
              <div class="text">{{this.selectedUser.card_exp_date}}</div>
            </div>
          </div>
          <hr>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import BootStrapAlert from "@/components/utils/Alert";
import EditTypeEnum from "@/enums/EditTypeEnum";
import {roles} from "@/static/roles"
import {
  required,
  helpers,
  sameAs
} from "@vuelidate/validators";

import useVuelidate from "@vuelidate/core";

export default {
  name: "userdetail",
  setup(){
    return {
      v$: useVuelidate()
    }
  },
  data(){
    return{
      EditTypeEnum: EditTypeEnum,
      roles: roles
    }
  },
  validations(){
    return{
      user: {
        name: {
          required: helpers.withMessage("Name is required", required),
        },
        new_password: {
          required: helpers.withMessage("New Password is required", required),
        },
        confirm_password: {
          sameAsPassword: sameAs(this.user.new_password)
        },
      },
    }
  },
  components: {
    BootStrapAlert
  },
  methods: {
    ...mapActions("adminUserDetail", ["setUser", "setNameText", "setEditEnabled", "changeName", "setNewPasswordText",
      "setConfirmPasswordText", "changeUserPassword", "setRole", "changeUserRole"]),
    updateNameText(e){
      this.setNameText(e.target.value);
    },

    OnClickedEditBtn(index){
      this.setEditEnabled(index);
    },

    OnClickedBackBtn(){
      this.$router.push({
        name: 'AdminUsers'
      })
    },

    OnUpdatePasswordText(e){
      this.setNewPasswordText(e.target.value);
    },

    OnUpdateConfirmPasswordText(e){
      this.setConfirmPasswordText(e.target.value);
    },

    OnUpdateRole(e){
      this.setRole(e.target.value);
    },

    OnClickedChangeBtn(index){
      this.$isLoading(true);
      switch (index) {
        case EditTypeEnum.NAME:
          this.v$.user.name.$touch();
          if (this.v$.user.name.$invalid) {
            return;
          }
          this.changeName()
              .finally(() => {
                this.$isLoading(false);
              });
          break;
        case EditTypeEnum.PASSWORD:
          this.v$.user.new_password.$touch();
          this.v$.user.confirm_password.$touch();
          if (this.v$.user.new_password.$invalid || this.v$.user.confirm_password.$invalid) {
            return;
          }
          this.changeUserPassword().finally(() => {
            this.$isLoading(false);
          });
          break;
        case EditTypeEnum.ROLE:
           this.changeUserRole().finally(() => this.$isLoading(false));
          break;
      }
    },
  },
  computed: {
    ...mapGetters("adminUserDetail", ["user", "edit_enabled", "selectedUser"])
  },

  beforeMount() {
    this.$isLoading(true);
    this.setUser(this.$route.query.id).finally(() => {this.$isLoading(false)});
  }
}
</script>

<style lang="scss" src="./userdetail.scss" scoped></style>